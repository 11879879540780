import React from 'react'
import Marked from 'react-markdown'
import './role.scss'
import loadable from '@loadable/component'
const Fade = loadable(() => import('../transition/Fade'))

const Role = ({ data }) => {
  const teamData = data.frontmatter
  return (
    <div
      className="role-section d-lg-flex justify-content-lg-between"
      id="common-px"
    >
      <div className="first-column text-center text-lg-left">
        <div className="mb-3">
          <svg
            width="60"
            height="52"
            viewBox="0 0 60 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.2034 23.6544C17.7257 23.229 16.2479 23.0131 14.8104 23.0131C12.5906 23.0131 10.7383 23.5204 9.30205 24.1416C10.6867 19.0726 14.013 10.3261 20.6391 9.34109C21.2527 9.24983 21.7556 8.80612 21.923 8.20884L23.3712 3.02905C23.4933 2.591 23.4209 2.12211 23.1711 1.74197C22.9212 1.36182 22.5196 1.10818 22.0696 1.0465C21.5806 0.979787 21.0821 0.945801 20.5881 0.945801C12.6346 0.945801 4.75793 9.24731 1.43418 21.1338C-0.516897 28.1073 -1.089 38.5915 3.71694 45.1905C6.40628 48.8831 10.3298 50.855 15.3787 51.052C15.3995 51.0526 15.4196 51.0532 15.4404 51.0532C21.67 51.0532 27.194 46.8578 28.8745 40.8516C29.8783 37.261 29.4246 33.4954 27.5956 30.2459C25.7861 27.0329 22.806 24.691 19.2034 23.6544Z"
              fill="#101010"
            />
            <path
              d="M58.199 30.2466C56.3896 27.0329 53.4095 24.691 49.8069 23.6544C48.3291 23.229 46.8513 23.0131 45.4144 23.0131C43.1946 23.0131 41.3417 23.5204 39.9055 24.1416C41.2901 19.0726 44.6164 10.3261 51.2431 9.34109C51.8568 9.24983 52.359 8.80612 52.5271 8.20884L53.9753 3.02905C54.0974 2.591 54.025 2.12211 53.7751 1.74197C53.5259 1.36182 53.1243 1.10818 52.6737 1.0465C52.1853 0.979787 51.6868 0.945801 51.1921 0.945801C43.2387 0.945801 35.362 9.24731 32.0376 21.1338C30.0872 28.1073 29.5151 38.5915 34.3216 45.1918C37.0103 48.8837 40.9345 50.8562 45.9828 51.0526C46.0035 51.0532 46.0237 51.0538 46.0451 51.0538C52.2741 51.0538 57.7987 46.8584 59.4792 40.8522C60.4818 37.2616 60.0274 33.4954 58.199 30.2466Z"
              fill="#101010"
            />
          </svg>
        </div>
        <Fade timeout={1000}>
          <p>
            <Marked
              parserOptions={{ commonmark: true }}
              className={`Content`}
              source={teamData.quote}
            />
          </p>
        </Fade>
        <a class="project-btn" href="/our-team">
          <svg
            width="12"
            height="20"
            viewBox="0 0 12 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="back-icon"
          >
            <path
              className="icon"
              d="M9.53929 0.198862L0.200471 9.53831C0.0668936 9.67175 -4.44709e-07 9.82526 -4.37119e-07 9.99892C-4.29528e-07 10.1726 0.0668936 10.3264 0.200471 10.4599L9.53929 19.7986C9.67308 19.9323 9.82659 19.999 10.0003 19.999C10.174 19.999 10.3277 19.9321 10.4612 19.7986L11.4629 18.7968C11.5965 18.6632 11.6633 18.5095 11.6633 18.3358C11.6633 18.1621 11.5965 18.0084 11.4629 17.8748L3.58714 9.99892L11.4634 2.12271C11.597 1.9892 11.6633 1.83541 11.6633 1.66203C11.6633 1.4881 11.5966 1.33437 11.4634 1.20087L10.4613 0.198932C10.3278 0.0654254 10.174 -0.000908343 10.0004 -0.000908335C9.82659 -0.000976992 9.67287 0.0653549 9.53929 0.198862Z"
            />
          </svg>
          TEAM
        </a>
      </div>
      <div className="second-column">
        <Fade timeout={500}>
          <h2>What I do ?</h2>
        </Fade>
        <Fade timeout={1000}>
          <p>
            <Marked
              parserOptions={{ commonmark: true }}
              className={`Content`}
              source={teamData.whatIDo}
            />
          </p>
        </Fade>
      </div>
    </div>
  )
}

export default Role
